<template>
  <div class="w-100">
    <h4>{{$t('message.VisaReports')}}</h4>
    <b-card>
      <b-row>
        <b-col md="3">
          <v-select :options="branchs" label="name" v-model="filterBranch" ></v-select>
        </b-col>
        <b-col md="3">
          <input type="date" class="form-control">
        </b-col>
        <b-col md="3">
          <input type="date" class="form-control">
        </b-col>
        <b-col md="3">
          <b-button variant="primary" @click="clientReports" size="sm">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3">
        <b-row>
          <b-col md="8">
            <b-card-body v-if="branchClient.series.length">
              <vue-apex-charts
                  v-if="branchClient.series.length"
                  type="pie"
                  height="450"
                  :options="branchClient.chartOptions"
                  :series="branchClient.series"
              />
            </b-card-body>
          </b-col>
          <b-col md="4">
            <b-table :fields="operatorFields" :items="reportTable"></b-table>
          </b-col>
        </b-row>
    </b-card>
  </div>
</template>

<script>
import { getBranches } from '@/api/branchs/branchs'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'
import { getBranchClientReports } from '@/api/reports/branch'
import ClientsByDate from '@/views/reports/components/ClientsByDate'
import { getVisaReports } from '@/api/reports/visa'
import { $themeColors } from '@themeConfig'

const chartColors = {
  column: {
    series1: '#826af9',
    series2: '#d2b0ff',
    bg: '#f8d3ff',
  },
  success: {
    shade_100: '#7eefc7',
    shade_200: '#06774f',
  },
  donut: {
    series1: '#ffe700',
    series2: '#00d4bd',
    series3: '#826bf8',
    series4: '#2b9bf4',
    series5: '#FFA1A1',
  },
  area: {
    series3: '#a4f8cd',
    series2: '#60f2ca',
    series1: '#2bdac7',
  },
}

export default {
  name: 'VisaReports',
  components: {
    ClientsByDate,
    vSelect,
    flatPickr,
    VueApexCharts,
  },
  data() {
    return {
      visaReports:[],
      branchClients: [],
      chartLabels: [],
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      fields: [
        {key: 'branch.name', label: this.$t('message.Branch')},
        {key: 'total', label: this.$t('message.Total')},
      ],
      lineLabels: [],
      lineValues: [],
      branchClient: {
        series: [],

      },
      reportTable:[],
      branchs: [],
      filterBranch: null,
      filters: {
        branch_id: null,
        start_date: null,
        end_date: null,
      },
      operatorFields: [
        {key:'author',label: this.$t('message.Author')},
        {key:'total',label: this.$t('message.Total')},
      ],
    }
  },
  created() {
    this.fetchBranchs();
    this.clientReports()
  },
  computed: {
    getOptions() {
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          chartColors.donut.series1,
          chartColors.donut.series5,
          chartColors.donut.series3,
          chartColors.donut.series2,
        ],
        xaxis:{categories:this.chartLabels},
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}`
                  },
                },
              },
            },
          },
        },
        labels: this.chartLabels,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    }
  },
  methods: {
    fetchBranchs(){
      getBranches().then(res => {
        this.branchs = res.data.data;
      })
    },
    clientReports() {
      this.filters.branch_id = this.filterBranch ? this.filterBranch.id : null;
      getVisaReports(this.filters).then(res => {
        this.reportTable = res.data.data;
        this.branchClient.series = res.data.operatorValues;
        this.chartLabels = res.data.operatorLabels
        this.branchClient.chartOptions = {
          ...this
          .branchClient.chartOptions,...{labels: res.data.operatorLabels}
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
